<template>
  <!--
    Used to display the configuration(s) of the relevant device.
  -->
  <Portlet
    :title="'Actioncam ' + $t('configuration')"
    icon="cog"
    class="actionCamDetailConfiguration mt-4 ml-4"
  >
    <template
      v-if="configuration"
      slot="buttons"
    >
      <button
        class="btn btn-secondary btn-sm ml-4"
        @click="toggleConfiguration()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="edit"
        />
        <span>{{ $t('edit') }}</span>
      </button>
    </template>

    <template v-if="editConfigurationBoolean">
      <DeviceDetailConfigurationEdit
        v-if="configuration"
        :configuration="configuration"
        :uuid="device.uuid"
        :restart-app-command-available="restartAppCommandAvailable"
        :restart-app-command="restartAppCommand"
        install-command-available="false"
        :install-command="null"
        :configuration-id="device.activeDeviceConfigurationId"
        :type="device.applicationType"
        @toggleConfiguration="toggleConfiguration()"
        @getConfiguration="getConfig()"
      />
    </template>

    <template v-else-if="configuration">
      <div
        v-if="configuration"
        id="conf"
      >
        <DeviceDetailStandardConfiguration
          :configuration="configuration"
        />
      </div>
    </template>
    <p v-else>
      {{ $t('noDataAvailable') }}
    </p>
  </Portlet>
</template>

<script>
export default {
  name: 'DeviceDetailConfiguration',
  components: {
    DeviceDetailStandardConfiguration: () => import('@/components/Device/DeviceDetail/DeviceDetailStandardConfiguration.vue'),
    DeviceDetailConfigurationEdit: () => import('@/components/Device/DeviceDetail/DeviceDetailConfigurationEdit.vue')
  },
  props: {
    deviceId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      configuration: null,
      editConfigurationBoolean: false,
      device: null
    }
  },
  computed: {
    restartAppCommandAvailable: function () {
      if(!this.device.vpnIpAddress || !this.device.commands)
      {
        return false;
      }
      else if (this.device.commands.filter(p => p.name == "restartApp").length === 1) {
        return true;
      }
      return false;
    },
    restartAppCommand: function () {
      if (!this.restartAppCommandAvailable) {
        return new Object();
      }
      else{
        return this.device.commands.filter(p => p.name == "restartApp")[0];
      }
      }
  },
  created () {
    this.getDevice().then(() => {
      this.getDeviceCommands();
      this.getConfig();
    })
  },
  methods: {
    toggleConfiguration () {
      this.editConfigurationBoolean = !this.editConfigurationBoolean;
    },
    async getDevice () {
      this.loading = true;
      await this.axios.get(`/CentralDeviceManagement/Get?deviceUuid=${this.deviceId}`)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          // save the response as devices
          this.device = response.data;
        })
    },
    async getDeviceCommands (){
        await this.axios.get(`/CentralDeviceManagement/GetDeviceCommands?deviceUuid=${this.deviceId}`) 
        .then((commands) => {
            this.device.commands = commands.data;         
        })
        .finally(() => {
          this.loading = false;
        }); 
    },
    getConfig () {
       this.axios.get(`/CentralDeviceManagement/GetDeviceConfigurationById?deviceUuid=${ this.device.uuid }&configurationId=${ this.device.activeDeviceConfigurationId }`)
        .then((response) => {
          if (!response) {
            return;
          }
          if (!response.data) {
            return;
          }
          this.configuration = response.data.configuration;
        });
    }
  }
}
</script>
